<template>
    <div class="mra-login">
        <the-header></the-header>
        <div class="content-area">
            <div class="container" style="padding-top:13px;">
                <div class="row pt-6 pb-6">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <img class="img-fluid" src="https://cra.train321.com/img/signup/image1.png"/>
                            </div>
                            <div class="training-content">
                                <h3>Individual Sign Up</h3>
                                <router-link class="btn ctm-btn  btn-login" to="/mra-register">Click Here</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <img class="img-fluid" src="https://cra.train321.com/img/signup/image2.png"/>
                            </div>
                            <div class="training-content">
                                <h3>Company Sign Up</h3>
                                <router-link class="btn ctm-btn  btn-login" to="/mra-corporate-register">Click Here</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="training-card">
                            <div class="training-img">
                                <img class="img-fluid" src="https://cra.train321.com/img/signup/image3.png"/>
                            </div>
                            <div class="training-content">
                                <router-link class="btn-login-h3" to="/mra-register"><h3 class="h3-mra">Sexual Harassment Prevention Training</h3></router-link>
                                <div>
                                    <router-link class="btn ctm-btn btn-login" to="/mra-register?course_type=sexual_harrasment">Individuals</router-link>
                                    <router-link class="btn ctm-btn companies btn-login" to="/mra-corporate-register?course_type=sexual_harrasment">Companies</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <the-footer/>
    </div>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";

import '@/mra/partials/responsive.css';

import TheHeader from '@/mra/partials/Header.vue';
import TheFooter from '@/mra/partials/Footer.vue';

export default {
    components: {
        TheHeader,
        TheFooter,
    },
    data() {
        return {};
    },
    created: function () {
    },
    methods: {}
};
</script>
<style scoped>
.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    font-size: 17px;
    margin-right: 5px;
}

.login-inputs {
    padding-top: 20px;
}

.login {
    margin-right: 0px !important;
}

.training-card {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%);
    transition: all .22s;
    transform: translateZ(0);
}

.training-content {
    position: relative;
}

.training-content {
    padding: 20px;
    min-height: 160px;
}

.training-content h3 {
    color: #284A5B;
    font-size: 24px;
    margin-bottom: 20px;
}

#footer-main {
    height: 60px;
    padding: 10px 0px;
}

footer {
    background-color: #F3F3F3;
    padding: 15px 0;
    width: 100%;
    bottom: 0;
}

.footer-link {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}

.footer-link li:not(:last-child) {
    margin-right: 15px;
}

.footer-link li a {
    color: #8A8686;
    font-size: 14px;
}

.social-link {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.social-link li:not(:last-child) {
    margin-right: 10px;
}

.container-fluid {
    margin: 0px !important;
    padding: 0px !important;
}

</style>
