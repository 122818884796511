<template>
    <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
        <form role="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="row">
                <div class="col-md-4">
                    <base-input
                        v-model="form.username"
                        :rules="{ required: true }"
                        name="Username"
                        placeholder="Username"
                        prepend-icon="fas fa-user"
                    ></base-input>
                </div>
                <div class="col-md-4">
                    <base-input
                        v-model="form.password"
                        :rules="{ required: true }"
                        name="Password"
                        placeholder="Password"
                        prepend-icon="fas fa-lock"
                        type="password"
                    ></base-input>

                    <router-link class="underline-class frg-link forget-mra" to="/forget_password"
                    >Forgot Password?
                    </router-link
                    >
                </div>
                <div class="col-md-3">
                    <div class="btnGroup">
                        <button class="btn login-btn btn-login" native-type="submit">
                            Login
                        </button>
                        <router-link :to="url" class="btn login-btn btn-signup"
                        >Sign Up
                        </router-link
                        >
                    </div>
                </div>
            </div>
        </form>
        <p v-if="errors.invalid" class="error">{{ errors.invalid }}</p>
    </validation-observer>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import BaseInput from "../../components/Inputs/BaseInput.vue";

export default {
    name: "login-input",
    components: {BaseInput},
    props: {
        url: {
            require: false,
            default: '/signup'
        }
    },
    data() {
        return {
            loading: false,
            form: {
                username: "",
                password: ""
            },
            errors: {
                invalid: ""
            }
        };
    },
    created: function () {
        if (this.$route.query.email && this.$route.query.password) {
            this.form.username = this.$route.query.email;
            this.form.password = this.$route.query.password;

            this.onSubmit();
        }
    },
    methods: {
        onSubmit() {
            this.loading = true;
            this.$http
                .post("user/login", {
                    email: this.form.username,
                    password: this.form.password
                })
                .then(resp => {
                    localStorage.setItem("hot-token", resp.data.token);
                    localStorage.setItem("hot-user", resp.data.role);
                    localStorage.setItem("hot-logged-user", resp.data.user_id);
                    localStorage.setItem("hot-user-full-name", resp.data.full_name);
                    let headers = {
                        authorization: "Bearer " + resp.data.token,
                        "content-type": "application/json"
                    };
                    this.$http.defaults.headers.authorization =
                        "Bearer " + resp.data.token;
                    let admin = "";
                    let level = "";

                    switch (resp.data.role) {
                        case "super-admin":
                            admin = "super_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username
                                    },
                                    {headers}
                                )
                                .then(resp => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        case "company-admin":
                            admin = "admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/data",
                                    {
                                        email: this.form.username
                                    },
                                    {headers}
                                )
                                .then(resp => {
                                    if (resp.data.level) {
                                        localStorage.setItem("hot-company-level", "parent");
                                    } else {
                                        localStorage.setItem("hot-company-level", "child");
                                    }
                                    localStorage.setItem("hot-admin-id", resp.data.admin_id);
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-company-name", resp.data[0].name);
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "manager":
                            admin = "manager";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username
                                    },
                                    {headers}
                                )
                                .then(resp => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    this.$router.push("/dashboard");
                                });
                            break;
                        case "employee":
                            admin = "employee";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "employees/user_data",
                                    {
                                        user_name: this.form.username
                                    },
                                    {headers}
                                )
                                .then(resp => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    localStorage.setItem("hot-user-name", resp.data[0].full_name);
                                    localStorage.setItem(
                                        "hot-user-number",
                                        resp.data[0].phone_num
                                    );
                                    localStorage.setItem(
                                        "hot-user-2fa",
                                        resp.data[0].is_2f_authenticated
                                    );
                                    if (resp.data[0].employee_status == 0) {
                                        this.errors.invalid = "Account is Deactivated by Admin..!!";
                                    } else {
                                        this.$router.push("/dashboard");
                                    }
                                });
                            break;
                        case "sub-admin":
                            admin = "sub_admin";
                            localStorage.setItem("hot-sidebar", admin);
                            this.$http
                                .post(
                                    "company/managerdata",
                                    {
                                        email: this.form.username
                                    },
                                    {headers}
                                )
                                .then(resp => {
                                    localStorage.setItem("hot-user-id", resp.data[0].id);
                                    this.$router.push("/dashboard");
                                });

                            break;
                        default:
                            //this.$router.push("/login");
                            this.errors.invalid = "Not Valid..!!";
                    }
                })
                .catch(function (error) {
                    let errorText = "Something went wrong! Please try again later.";
                    if (error.response && error.response.status === 422) {
                        errorText = error.response.data.message;
                    }
                    self.processing = false;
                    Swal.fire({
                        title: "Error!",
                        html: errorText,
                        icon: "error"
                    });
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>
<style scoped>
.login-btn {
    background-color: #0d447c;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
    padding: 8px 15px;
}

.frg-link {
    font-size: 12px;
    right: 15px;
}

.btnGroup {
    display: flex;
    justify-content: space-between;
}

.btnGroup .login-btn {
    min-width: 100px;
}

.btn-login {
    background-color: #4a88f9 !important;
    border-radius: 10px !important;
}

.btn-signup {
    background-color: #fcb040 !important;
    border-radius: 10px !important;
    font-weight: 300 !important;
}

.header-section {
    height: 95px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 40%);
    border-bottom: 1px solid #ccc;
    position: relative;
    z-index: 99;
    background: #000000;
}

</style>
